import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import Header from "./header"
import Footer from "./footer"
import '../../styles/tailwind.css'
import '../../styles/variables.scss'
import '../../styles/main.css'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4A5568',
      contrastText: '#fff',
    },
    secondary: {
      main: '#718096',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      '"Lato"',
      '-apple-system',
      'BlinkMacSystemFont',
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#fff",
        padding: "14px 31px"
      },
    },
  },
});


const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)
  
    return (
      <ThemeProvider theme={theme}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>       
    )
  }
  
  export default Layout