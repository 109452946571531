import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid, Hidden } from '@material-ui/core'

import FooterLogo from '../../images/svg/logo-footer.svg'
import Facebook from '../../images/facebook.png'

export default function Footer() {

    return (
      <>
        <footer className="white-text bg-blue-600 pt-40 pb-20 text-center md:text-left">
          <Container className="pb-20 md:pb-40">
            <Grid items container spacing={3}>
              <Grid item md={4} xs={12} className="flex">
                <div className="inline-flex flex-col mx-auto md:m-0">
                  <img src={FooterLogo} />
                  <h5>Follow us for the latest <br/>offers &amp; promotions.</h5>
                  <a href="https://www.facebook.com/jjautomotiveinsight/" target="_blank" className="facebook-logo mt-6"><img src={Facebook} alt="Visit us on Facebook" className="mx-auto md:m-0" /></a>
                </div>
              </Grid>
              <Grid item md={4} xs={12} className="flex items-center justify-center">
                <div className="inline-flex flex-col mx-auto md:m-0">
                  <p><strong>Contact Us</strong></p>
                  <p>850 Mosley St. Wasaga Beach, L9Z 2H4</p>
                  <p><a href="tel:7054222111" title="Call Our Office">705.422.2111 (Office)</a></p>
                  <p><a href="tel:7055002925" title="Reach Us 24/7">705.500.2925 (24hr)</a></p>
                  <p><a href="mailto:jjautomotiveinsight@outlook.com" title="Email Us">jjautomotiveinsight@outlook.com</a></p>
                </div>
              </Grid>
              <Grid item md={4} xs={12} className="flex items-center justify-center">
                <div className="inline-flex flex-col mx-auto md:m-0">
                  <p><strong>Hours</strong></p>
                  <p>8 AM to 6 PM Monday to Friday</p>
                  <p>Saturday by appointment only</p>
                  <p>Closed Sunday</p>
                </div>
              </Grid>
            </Grid>
          </Container>
          <Container>
            <Grid items container>
              <Grid item md={12} xs={12}>
                <div>
                  <p><Link to="/">JJAUTOMOTIVEINSIGHT</Link> &copy; {new Date().getFullYear()} <Hidden mdDown>|</Hidden> <Hidden mdUp><br /></Hidden>ALL RIGHTS RESERVED</p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </footer>  
      </>  
    )
}
