import React from 'react'
import { Link } from 'gatsby'

export default function Navigation() {
  
    return (
        <>        
        <nav className="z-10">
            <ul className="md:inline-flex md:space-x-12">
                <li><Link to="/" title="Home" className="teal uppercase font-semibold hover:opacity-50 transition duration-300 ease-in-out tracking-wider" activeClassName="active">Home</Link></li>
                <li><Link to="/about-us" title="About Us" className="teal uppercase font-semibold hover:opacity-50 transition duration-300 ease-in-out tracking-wider" activeClassName="active">About Us</Link></li>
                <li><Link to="/our-services" title="Our Services" className="teal uppercase font-semibold hover:opacity-50 transition duration-300 ease-in-out tracking-wider" activeClassName="active">Our Services</Link></li>
                <li><Link to='/contact-us' title="Contact" className="teal uppercase font-semibold hover:opacity-50 transition duration-300 ease-in-out tracking-wider" activeClassName="active">Contact Us</Link></li>
            </ul>
        </nav>
        </>
    )
}