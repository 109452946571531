import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid, Hidden } from '@material-ui/core'
import Navigation from './navigation/navigation'

import MobileNavigation from "../theme/navigation/mobile-navigation"
import Logo from "../../images/svg/logo.svg"

export default function Header() {
  return (
    <>
    <div className="headerTop bg-blue-600 p-4">
      <Container className="white-text text-center md:text-left">
        <Grid container direction="row">
          <p>
            Call us today and make an appointment: <a href="tel:7054222111" target="_blank">705.422.2111</a>
          </p> 
        </Grid>
      </Container>
    </div>
    <header className="white-bg py-16">
    <Container>
      <Grid items container className="flex items-center">
        <Grid item xs={4}>
          <Link to="/" className="logo"><img src={Logo} alt="JJ Automotive" /></Link>
        </Grid>
        <Grid item xs={8} className="flex items-center justify-end">
          <Hidden smDown>
            <Navigation />
          </Hidden>          
          <Hidden mdUp>
            <MobileNavigation />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  </header>
  </>
  )
}